.Navbar {
  height: 100px;
  transition: all .3s;
  padding: 0 60px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0; }
  .Navbar:before {
    z-index: -1;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    position: absolute;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5), transparent);
    transition: .2s all;
    opacity: 0;
    transform: translateY(-50px); }
  .Navbar.sticky:before {
    opacity: 1;
    transform: translateY(0); }
  .Navbar .home-brand {
    float: left; }
    .Navbar .home-brand img {
      border-radius: 100%;
      height: 75px;
      transition: all .3s; }
      @media (hover: hover) {
        .Navbar .home-brand img:hover {
          transform: rotate(30deg); } }
  .Navbar .link {
    padding: 0 16px;
    position: relative;
    transition: all 1s; }
    .Navbar .link:after {
      content: '';
      display: block;
      width: 0;
      height: 4px;
      background: white;
      transform: translateY(-20px);
      transition: all .2s;
      margin: auto;
      border-radius: 10px; }
    @media (hover: hover) {
      .Navbar .link:hover:after {
        width: 100%; } }
  .Navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden; }
  .Navbar li {
    transition: all .3s;
    line-height: 100px;
    float: right;
    font-size: 28px;
    text-transform: uppercase; }
    .Navbar li:hover {
      cursor: pointer; }
  @media screen and (max-width: 800px) {
    .Navbar .link {
      font-size: 24px; }
    .Navbar .home-brand img {
      height: 50px; } }
  @media screen and (max-width: 600px) {
    .Navbar {
      padding: 0 20px; }
      .Navbar .link {
        font-size: 20px;
        padding: 0 8px; }
      .Navbar .home-brand img {
        height: 55px; } }

.source-button {
  display: block;
  transition: all .2s;
  border: 2px solid white;
  color: white;
  background: #530080;
  text-transform: uppercase;
  letter-spacing: 3px;
  border-radius: 2px;
  position: fixed;
  bottom: 10px;
  right: -50px;
  transform: translateX(100%);
  padding: 10px 20px;
  font-size: 10px;
  margin: 0; }
  .source-button:hover {
    cursor: pointer;
    text-decoration: none;
    font-size: 13px;
    color: white; }
  .source-button:focus {
    outline: 0; }

.sticky {
  position: fixed;
  top: 0;
  z-index: 1; }
  .sticky .source-button {
    right: 10px;
    transform: translateX(0); }
  .sticky + .content {
    padding-top: 52px; }
