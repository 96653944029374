.Button {
  transition: all .2s;
  padding: 25px 60px;
  border-radius: 20px;
  border: 2px solid white;
  color: white;
  background: transparent;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 3px; }
  @media (hover: hover) {
    .Button:hover {
      cursor: pointer;
      border-color: purple;
      background-color: white;
      color: purple; } }
  .Button:focus {
    outline: 0; }
