.Project {
  text-align: center;
  padding: 50px 0;
  letter-spacing: 2px;
  margin: 0 5px; }
  .Project img {
    max-width: 800px;
    width: 100%;
    padding: 0 30px;
    margin-top: 30px; }
    @media screen and (max-width: 500px) {
      .Project img {
        padding: 0 5px; } }
  .Project .sub-hr {
    max-width: 200px;
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .Project .project-description {
    max-width: 500px;
    margin: auto; }
