.About {
  position: relative;
  text-align: center;
  overflow: hidden;
  background: #380066; }
  .About .about-content {
    padding: 55px 15%; }
    @media screen and (max-width: 800px) {
      .About .about-content {
        padding: 55px 8%; } }
    @media screen and (max-width: 500px) {
      .About .about-content {
        padding: 55px 5px; } }
  .About.mask {
    background: rgba(83, 0, 128, 0.5); }
  .About img {
    height: auto;
    width: 100%; }
  .About h1 {
    text-transform: uppercase; }
  .About p {
    padding: 0 10px;
    text-align: left; }
