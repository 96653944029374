body {
  margin: 0;
  padding: 0;
  font-family: 'Dosis', sans-serif !important;
  color: white; }

p {
  font-size: 20px; }

.highlight {
  color: #E73C7E; }
