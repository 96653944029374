.Banner {
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .Banner .banner-content {
    width: 800px;
    padding: 20px; }
  .Banner .banner-title {
    letter-spacing: 3px;
    font-size: 100px; }
    @media screen and (max-width: 470px) {
      .Banner .banner-title {
        font-size: 80px; } }
  .Banner .signature > * {
    stroke-dasharray: 1600;
    stroke-dashoffset: 1600; }
  .Banner #Path {
    animation: forwards dash-first ease-in 5s; }
  .Banner #Path-2 {
    animation: forwards dash-second ease-out 5s; }
  .Banner #animated:hover {
    cursor: pointer; }
  @media screen and (max-width: 470px) {
    .Banner h3 {
      padding: 0 10px;
      font-size: 1.5rem; } }

@keyframes dash-first {
  40% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: 0; } }

@keyframes dash-second {
  40% {
    stroke-dashoffset: 1600; }
  100% {
    stroke-dashoffset: 0; } }
