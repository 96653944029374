.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 4px; }
  .Header .purple_bar {
    margin: 15px;
    height: 15px;
    width: 100px;
    background-color: #530080;
    flex-grow: 1;
    border-radius: 20px; }
  .Header .header-title {
    margin: 0 5%; }
