.Contact {
  text-align: center;
  height: 435px;
  background-color: #530080;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .Contact > * {
    margin: 20px; }
